<script setup lang="ts">
import {
	Dialog,
	DialogContent,
	DialogHeader,
	Button,
	DialogFooter,
	DialogClose,
} from '@laam/ui/base';
import { PhBell } from '@phosphor-icons/vue';
import { useLoganStore } from '~/stores/logan';
import { useNotifyMeBooking } from '~/data/bookings';
import NotifyMeBackground from '~/icons/NotifyMeBackground.vue';
import { useToast } from '@laam/ui/base';
const { toast } = useToast();

const notifyMeProduct: Ref<{
	show: false;
	shopifyId: 0;
	url: '';
	disabled: number[];
}> = inject('notifyMeProduct')!;

const LoganStore = useLoganStore();
const showLogan = ref(true);
const showTrigger = ref(true);

const { mutate: notifyMeMutation, isPending, error } = useNotifyMeBooking();
const { $eventClient } = useNuxtApp();

onMounted(() => {
	if (LoganStore.isSignedIn) {
		showTrigger.value = false;
		showLogan.value = false;
	}
});

watch(
	() => LoganStore.isSignedIn,
	(newValue, oldValue) => {
		if (newValue === true && oldValue === false) {
			showLogan.value = false;
		}
	},
);

watch(error, (newError) => {
	if (newError) {
		toast({
			title: 'Error',
			description: `${error.value?.message}`,
			variant: 'error',
		});
	}
});

const emits = defineEmits(['disableNotifyMe']);

const handleClick = () => {
	const payload = {
		...(LoganStore.email && { email: LoganStore.email }),
		...(LoganStore.phoneNumber && { phone: LoganStore.phoneNumber }),
		product_shopify_id: notifyMeProduct.value.shopifyId,
		product_url: `https://laam.pk/products/${notifyMeProduct.value.url}`,
	};
	notifyMeMutation(payload, {
		onError: (error) => {
			toast({
				title: 'Notify me error!',
				duration: 3000,
			});
			throw new Error(`Notify me error! ${error.message.toString()}`);
		},
		onSuccess: () => {
			$eventClient.login({
				phone: LoganStore.phoneNumber,
				email: LoganStore.email,
			});
		},
	});
	emits('disableNotifyMe', notifyMeProduct.value.shopifyId);
};
</script>
<template>
	<Dialog v-model:open="notifyMeProduct.show">
		<DialogContent
			:side="isMobileView() ? 'bottom' : 'center'"
			class="w-[80%] rounded-medium gap-none lg:w-[30%] max-h-max"
			:class="{
				'max-h-max -translate-y-1/2': !showLogan,
			}"
		>
			<DialogHeader
				class="flex flex-col items-center justify-start relative w-full top-[-30px] lg:border-none"
			>
				<NotifyMeBackground />
				<div
					class="relative flex flex-col items-center w-full p-5xl gap-xl pb-none"
				>
					<div
						class="flex justify-center items-center p-xl rounded-[28px] border-[8px] border-gray-50 bg-gray-100"
					>
						<PhBell />
					</div>
					<p v-if="showLogan" class="text-lg font-semibold text-gray-900">
						Launching Soon
					</p>
					<p v-else class="text-lg font-semibold text-gray-900">
						We'll keep you posted
					</p>
				</div>
				<p v-if="showLogan" class="w-full text-sm text-center text-gray-600">
					Please share your contact info to receive an alert when the product is
					back
				</p>
				<p v-else class="w-full text-sm text-center text-gray-600">
					Thanks for signing up! See you soon in your inbox.
				</p>
			</DialogHeader>
			<div v-if="showLogan" class="w-full relative top-[-30px]">
				<Logan
					:render-form-only="true"
					:show-confirmation-text="false"
					:show-sign-in-with-buttons="false"
					sign-in-with-text="Confirm"
				/>
			</div>
			<DialogFooter v-else>
				<DialogClose class="w-full">
					<Button size="lg" class="w-full" @click="handleClick">
						<p v-if="!isPending">You’re on the List!</p>
						<LoganLoader v-else />
					</Button>
				</DialogClose>
			</DialogFooter>
		</DialogContent>
	</Dialog>
</template>
